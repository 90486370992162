import {
  analyticsEvents,
  OrderTypes,
  PageSource,
  ProductType,
} from '@mindfulchefuk/analytics'

import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { CTALocation } from '@mindfulchefuk/analytics/src/types'

export const instrumentProductAdded = ({
  productId,
  productName,
  productType,
  productCategory,
  productSubCategory,
  portionCount,
  productPrice,
  productQuantity,
  productSku,
  cartId,
  pageSource,
  upcomingDeliveryWeek,
  ctaLocation,
  recipeInPreferences = true,
}: {
  productId: string
  productName: string
  productType: Exclude<OrderTypes, 'both' | 'none'>
  productCategory: ProductType
  productSubCategory?: string
  portionCount?: number
  productPrice: number
  productQuantity: number
  productSku?: string
  cartId?: string
  pageSource?: PageSource
  upcomingDeliveryWeek?: number
  ctaLocation?: CTALocation
  recipeInPreferences?: boolean
}) => {
  const { name, data } = analyticsEvents.productAdded({
    productId,
    productName,
    productType,
    productCategory,
    productSubCategory,
    portionCount,
    productPrice,
    productQuantity,
    productSku,
    cartId,
    pageSource,
    upcomingDeliveryWeek,
    ctaLocation,
    recipeInPreferences,
    isAddon: false,
  })

  instrument(name, {
    rawPageSource: sessionStorage.getItem('referrerPath'),
    ...data,
  })
}
