import routesConfig from '@mindfulchefuk/config/routesConfig'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'
import { shopProductTypeConfig } from '@mindfulchefuk/features/Products/productTypeConfig'

const orderLinks: NavLinkData[] = [
  {
    label: 'Upcoming orders',
    id: 'upcoming-orders',
    href: routesConfig.upcoming.pathname,
    icon: 'delivery',
  },
  {
    label: 'Past orders',
    id: 'past-orders',
    href: routesConfig.past.pathname,
    icon: 'roundel-tick',
  },
  {
    label: 'Calendar',
    id: 'calendar',
    href: routesConfig.calendar.pathname,
    icon: 'calendar',
  },
]

const accountLinks: NavLinkData[] = [
  {
    id: 'my-subscription',
    label: 'My subscription',
    href: routesConfig.subscription.pathname,
  },
  {
    id: 'my-account-details',
    label: 'My account details',
    href: routesConfig.details.pathname,
  },
  {
    id: 'refer-a-friend',
    label: 'Refer a friend',
    href: routesConfig.referral.pathname,
  },
  {
    id: 'about-us',
    label: 'About us',
    href: 'https://www.mindfulchef.com/about-us',
    route: 'external',
  },
  {
    id: 'our-suppliers',
    label: 'Our suppliers',
    href: 'https://www.mindfulchef.com/suppliers',
    route: 'external',
  },
  {
    id: 'get-in-touch',
    label: routesConfig.contact.label,
    href: routesConfig.contact.pathname,
    icon: 'chat',
  },
]

const onboardingRecipeBoxes: NavLinkData = {
  id: 'recipe-boxes',
  label: 'Recipe boxes',
  icon: 'box',
  subNav: [
    {
      id: 'all-recipe-boxes',
      label: 'All recipe boxes',
      href: '/choose-recipes?initialMealPlan=Balanced',
    },
    {
      id: 'vegan-recipe-boxes',
      label: 'Vegan recipe boxes',
      href: '/choose-recipes?initialMealPlan=Plant-Based',
    },
    {
      id: 'family-recipe-boxes',
      label: 'Family recipe boxes',
      href: '/choose-recipes?initialPortionCount=4&initialMealPlan=Balanced',
    },
  ],
}

const onboardingAccountLinks = accountLinks.filter(
  (link) => link.id !== 'my-preferences'
)

const readyToGo: NavLinkData = {
  id: 'ready-to-go',
  label: 'Ready to go range',
  subNav: Object.values(shopProductTypeConfig).map((product) => ({
    label: product.shortTitle,
    href: routesConfig.onboardingShop.pathname(product.id),
    id: product.id,
  })),
  icon: 'knife-and-fork',
}

const signOut: NavLinkData = {
  id: 'sign-out',
  label: routesConfig.signOut.label,
  href: routesConfig.signOut.pathname,
}

const account: NavLinkData = {
  id: 'my-account',
  label: 'My account',
  subNav: accountLinks,
  icon: 'person',
}

const onboardingAccount: NavLinkData = {
  id: 'my-account',
  label: 'My account',
  subNav: onboardingAccountLinks,
  icon: 'person',
}

const giftCards: NavLinkData = {
  id: 'gift-cards',
  label: routesConfig.giftCard.label,
  href: routesConfig.giftCard.pathname,
  icon: 'gift-box',
}

const yourOrders: NavLinkData = {
  id: 'your-orders',
  label: 'Your orders',
  subNav: orderLinks,
}

const oneOffOrderCTA: NavLinkData = {
  id: 'one-off-order',
  label: 'Order a one-off box',
  href: routesConfig.calendar.pathname,
}

const yourRecipes: NavLinkData = {
  id: 'your-recipes',
  label: 'Your recipes',
  href: routesConfig.yourRecipes.pathname,
  icon: 'recipes',
}

export const signedInNav = {
  account,
  giftCards,
  onboardingAccount,
  onboardingRecipeBoxes,
  oneOffOrderCTA,
  orderLinks,
  readyToGo,
  signOut,
  yourOrders,
  yourRecipes,
}
