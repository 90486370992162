import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Delivery } from '@mindfulchefuk/features/Delivery/interfaces'
import { JsonapiError } from 'spraypaint'
import {
  getDeliveries,
  GetDeliveriesParams,
} from '@mindfulchefuk/features/Delivery/services/getDeliveries'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { addDays, subDays } from 'date-fns'
import { ORDER_RANGE, PAST_ORDER_RANGE } from '@mindfulchefuk/constants'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'

type Options<TData> = UseQueryOptions<
  Delivery[],
  { response: JsonapiError },
  TData
> & {
  today?: Date
  minDeliveryDate?: string
  maxDeliveryDate?: string
  includeShop?: boolean
}

const today = new Date()
const minDate = dateFormats.iso(subDays(today, PAST_ORDER_RANGE))
const maxDate = dateFormats.iso(addDays(today, ORDER_RANGE))

export const useGetDeliveries = <TData = Delivery[]>({
  enabled = true,
  includeShop,
  minDeliveryDate = minDate,
  maxDeliveryDate = maxDate,
  ...options
}: Options<TData> = {}) => {
  const customerId = getCustomerId()
  const params: GetDeliveriesParams = {
    customerId,
    maxDate: maxDeliveryDate,
    minDate: minDeliveryDate,
    includeShop,
  }

  return useQuery({
    queryKey: ['deliveries', 'list', { params }],
    queryFn: () => getDeliveries(params),
    ...options,
    enabled: enabled && !!maxDate && !!minDate && !!customerId,
  })
}
