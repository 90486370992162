import { NotificationId } from '@mindfulchefuk/features/Notifications/interfaces'
import { clearNotifications } from '@mindfulchefuk/features/Notifications/notificationsSlice'
import { RootState } from '@mindfulchefuk/types/store'
import { useRouter } from 'next/router'
import React, { useEffect, VoidFunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationPromoVoucherExperiment } from '@mindfulchefuk/features/Notifications/components/variants/NotificationPromoVoucherExperiment'
import { NotificationErrorWaitroseCustomersVitalitySignIn } from './variants/NotificationErrorWaitroseCustomersVitalitySignIn'
import { NotificationErrorDateReset } from './variants/NotificationErrorDateReset'
import { NotificationErrorDateInvalid } from './variants/NotificationErrorDateInvalid'
import { NotificationErrorPasswordFailedToUpdate } from './variants/NotificationErrorPasswordFailedToUpdate'
import { NotificationSuccessAccountDeactivated } from './variants/NotificationSuccessAccountDeactivated'
import { NotificationSuccessAccountPreferencesChanged } from './variants/NotificationSuccessAccountPreferencesChanged'
import { NotificationSuccessSubscriptionRestarted } from './variants/NotificationSuccessSubscriptionRestarted'
import { NotificationSuccessDeliveryCancelled } from './variants/NotificationSuccessDeliveryCancelled'
import { NotificationSuccessDeliveriesSkipped } from './variants/NotificationSuccessDeliveriesSkipped'
import { NotificationSuccessOrderCancelled } from './variants/NotificationSuccessOrderCancelled'
import { NotificationSuccessPasswordChanged } from './variants/NotificationSuccessPasswordChanged'
import { NotificationSuccessResetLinkSent } from './variants/NotificationSuccessResetLinkSent'
import { NotificationSuccessFeedbackShared } from './variants/NotificationSuccessFeedbackShared'
import { NotificationSuccessVoucherApplied } from './variants/NotificationSuccessVoucherApplied'
import { NotificationWarningAccountIsDeactivated } from './variants/NotificationWarningAccountIsDeactivated'
import { NotificationWarningOffline } from './variants/NotificationWarningOffline'
import { NotificationWarningVersionUpdate } from './variants/NotificationWarningVersionUpdate'
import { NotificationSuccessFrequencyUpdated } from './variants/NotificationSuccessFrequencyUpdated'
import { SubscriptionPaused } from './variants/NotificationSubscriptionPaused'
import { NotificationSuccessWinBackRedemption } from './variants/NotificationSuccessWinBackRedemption'
import { NotificationSuccessDeliverySkipped } from './variants/NotificationSuccessDeliverySkipped'
import { NotificationSuccessDeliveryDateChanged } from './variants/NotificationSuccessDeliveryDateChanged'

export const notificationComponents: Record<
  NotificationId,
  VoidFunctionComponent
> = {
  'error-date-invalid': NotificationErrorDateInvalid,
  'error-date-reset': NotificationErrorDateReset,
  'error-password-failed-to-update': NotificationErrorPasswordFailedToUpdate,
  'error-waitrose-customers-vitality-signin':
    NotificationErrorWaitroseCustomersVitalitySignIn,
  'promo-voucher-experiment': NotificationPromoVoucherExperiment,
  'success-account-deactivated': NotificationSuccessAccountDeactivated,
  'success-subscription-restarted': NotificationSuccessSubscriptionRestarted,
  'success-account-preferences-changed':
    NotificationSuccessAccountPreferencesChanged,
  'success-delivery-cancelled': NotificationSuccessDeliveryCancelled,
  'success-delivery-date-changed': NotificationSuccessDeliveryDateChanged,
  'success-deliveries-skipped': NotificationSuccessDeliveriesSkipped,
  'success-delivery-skipped': NotificationSuccessDeliverySkipped,
  'success-feedback-shared': NotificationSuccessFeedbackShared,
  'success-frequency-updated': NotificationSuccessFrequencyUpdated,
  'success-order-cancelled': NotificationSuccessOrderCancelled,
  'success-password-changed': NotificationSuccessPasswordChanged,
  'success-reset-link-sent': NotificationSuccessResetLinkSent,
  'success-win-back-redemption': NotificationSuccessWinBackRedemption,
  'success-voucher-applied': NotificationSuccessVoucherApplied,
  'warning-account-is-deactivated': NotificationWarningAccountIsDeactivated,
  'warning-offline': NotificationWarningOffline,
  'warning-subscription-paused': SubscriptionPaused,
  'warning-version-update': NotificationWarningVersionUpdate,
}

export const BannerNotifications = () => {
  const notifications = useSelector(
    (state: RootState) => state.notifications.list
  )

  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => dispatch(clearNotifications())
    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => router.events.off('beforeHistoryChange', handleRouteChange)
  }, [router, dispatch])

  return (
    <>
      {notifications.map((id) => {
        const Component = notificationComponents[id]

        return Component ? <Component key={id} /> : null
      })}
    </>
  )
}
