import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { isPast } from 'date-fns'
import { useRouter } from 'next/router'
import { ISODate } from '@mindfulchefuk/lib/dateFormats'

export const usePreviousDashboardUrl = (): {
  url: string
  name: 'your orders' | 'calendar' | 'past orders'
} => {
  const previousDashboardView = useSelector(
    (store: RootState) => store.navigation.previousDashboardView
  )

  const deliveryDate = useRouter().query.deliveryDate as ISODate

  if (
    previousDashboardView === 'past' ||
    (!previousDashboardView && isPast(deliveryDate))
  ) {
    return {
      url: routesConfig.past.pathname,
      name: 'past orders',
    }
  }

  if (previousDashboardView === 'calendar') {
    return {
      url: routesConfig.calendar.pathname,
      name: 'calendar',
    }
  }

  return {
    url: routesConfig.upcoming.pathname,
    name: 'your orders',
  }
}
