import { foodGroup as FoodGroup } from './mealPlanConfig'

type FoodGroupType = {
  value: FoodGroup
  label: string
  filterLabel?: string
  imageSource: string
}

export const foodGroupsConfig: FoodGroupType[] = [
  {
    value: 'Beef',
    label: 'Grass-fed British beef',
    imageSource: 'illustrations/beef-08-08-2023.png',
  },
  {
    value: 'Pork',
    label: 'British pork',
    imageSource: 'illustrations/pork-08-08-2023.png',
  },
  {
    value: 'Shellfish',
    label: 'Shellfish (never air-freighted)',
    imageSource: 'illustrations/shellfish-08-08-2023.png',
  },
  {
    value: 'Fish',
    label: 'Sustainably sourced fish',
    imageSource: 'illustrations/fish-08-08-2023.png',
  },
  {
    value: 'Poultry',
    label: 'British poultry',
    imageSource: 'illustrations/chicken-08-08-2023.png',
  },
  {
    value: 'Lamb',
    label: 'Grass-fed British lamb',
    imageSource: 'illustrations/lamb-08-08-2023.png',
  },
  {
    value: 'Vegan',
    label: 'Plant-powered proteins',
    imageSource: 'illustrations/broccoli-08-08-2023.png',
  },
  {
    value: 'Dairy',
    label: 'Dairy (contains milk)',
    filterLabel: 'Dairy',
    imageSource: 'illustrations/cheese-and-new-icon.png',
  },
]

//there is no vegetarian food group
export const meatFoodGroups: FoodGroup[] = ['Beef', 'Pork', 'Lamb', 'Poultry']
export const fishFoodGroups: FoodGroup[] = ['Fish', 'Shellfish']
export const veganFoodGroups: FoodGroup[] = ['Vegan']
export const dairyFoodGroups: FoodGroup[] = ['Dairy']
export const allFoodGroups: FoodGroup[] = foodGroupsConfig.map(
  (config) => config.value
)
export const getIncludedFoodGroups = (excludedFoodGroups: FoodGroup[]) =>
  allFoodGroups.filter((foodGroup) => !excludedFoodGroups.includes(foodGroup))
