import { analyticsEvents } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { CTALocation } from '@mindfulchefuk/analytics/src/types'

export const instrumentRecipeCTAClicked = (
  source: CTALocation,
  homepageVariant?: string | null
) => {
  const { name, data } = analyticsEvents.recipeCTAClicked(
    source,
    homepageVariant
  )

  instrument(name, { ...data })
}

export const instrumentR2goCTAClicked = (source: CTALocation) => {
  const { name, data } = analyticsEvents.r2gCTAClicked(source)

  instrument(name, { ...data })
}
