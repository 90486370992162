import {
  analyticsEvents,
  DeliveryStatus,
  OrderTypes,
  PageSource,
  ProductType,
  ReferralChannel,
} from '@mindfulchefuk/analytics'

import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { CTALocation } from '@mindfulchefuk/analytics/src/types'
import { ProductTypesInBasket } from '@mindfulchefuk/utils/analytics/amplitude/types'

export { instrumentBasketViewEvent } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentBasketViewEvent'

export { instrumentProductAdded } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentProductAdded'

export { instrumentProductViewEvent } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentProductViewEvent'

export { instrumentRecipeDeliverySkipped } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentRecipeDeliverySkipped'

export { instrumentDeliveryPageViewed } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentDelivery'

export {
  instrumentRecipeCTAClicked,
  instrumentR2goCTAClicked,
} from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentCTAClicks'

export * from './instrumentRecipeFeedback'
export * from './instrumentReportIssue'

export const instrumentCalendarDateSelected = ({
  order,
  deliveryStatus,
}: {
  order: OrderTypes
  deliveryStatus: DeliveryStatus
}) => {
  const { name, data } = analyticsEvents.calendarDateSelected({
    order,
    deliveryStatus,
  })

  instrument(name, data)
}

export const instrumentReferralButtonClicked = (pageSource: PageSource) => {
  const { name } = analyticsEvents.referralButtonClicked(pageSource)
  instrument(name)
}

export const instrumentReferralChannelClicked = (
  channel: ReferralChannel,
  pageSource: PageSource
) => {
  const { name, data } = analyticsEvents.referralChannelClicked(
    channel,
    pageSource
  )
  instrument(name, { ...data })
}

export const instrumentEditOrder = ({
  pageSource,
  productTypeInBasket,
  upcomingDeliveryWeek,
}: {
  pageSource: PageSource
  productTypeInBasket: ProductTypesInBasket
  upcomingDeliveryWeek: number
}) => {
  const { name, data } = analyticsEvents.editOrder({
    pageSource,
    productTypeInBasket,
    upcomingDeliveryWeek,
  })

  instrument(name, { ...data })
}

export const instrumentCategoryChosen = ({
  productCategory,
  previousCategory,
}: {
  productCategory: ProductType
  previousCategory: ProductType
}) => {
  const { name, data } = analyticsEvents.categoryChosen(
    productCategory,
    previousCategory
  )

  instrument(name, {
    rawPageSource: sessionStorage.getItem('referrerPath'),
    ...data,
  })
}

export const instrumentProductRemoved = ({
  productId,
  productName,
  productType,
  productCategory,
  productSubCategory,
  portionCount,
  productPrice,
  productQuantity,
  productSku,
  cartId,
  pageSource,
  upcomingDeliveryWeek,
  ctaLocation,
}: {
  productId: string
  productName: string
  productType: Exclude<OrderTypes, 'both' | 'none'>
  productCategory: ProductType
  productSubCategory?: string
  portionCount?: number
  productPrice: number
  productQuantity: number
  productSku?: string
  cartId?: string
  pageSource?: PageSource
  upcomingDeliveryWeek?: number
  ctaLocation?: CTALocation
}) => {
  const { name, data } = analyticsEvents.productRemoved({
    productId,
    productName,
    productType,
    productCategory,
    productSubCategory,
    portionCount,
    productPrice,
    productQuantity,
    productSku,
    cartId,
    pageSource,
    upcomingDeliveryWeek,
    ctaLocation,
    isAddon: false,
  })

  instrument(name, {
    rawPageSource: sessionStorage.getItem('referrerPath'),
    ...data,
  })
}

export const instrumentOrderSubmitted = ({
  productsInBasket,
  numberOfRecipes,
  totalAmount,
  totalRecipeAmount,
}: {
  productsInBasket: OrderTypes
  numberOfRecipes: number
  totalAmount: string
  totalRecipeAmount: string
}) => {
  const { name, data } = analyticsEvents.orderSubmitted({
    productsInBasket,
    numberOfRecipes,
    totalAmount,
    totalRecipeAmount,
  })

  instrument(name, data)
}

export const instrumentReadyToGoReviewViewed = ({
  deliveryAmount,
  totalReadyToGoAmount,
  totalDiscountAmount,
  promoCodesApplied,
  cartId,
  cartSize,
}: {
  deliveryAmount: number
  totalReadyToGoAmount: number
  totalDiscountAmount: number
  promoCodesApplied: string
  cartId: string
  cartSize: number
}) => {
  const { name, data } = analyticsEvents.readyToGoReviewViewed({
    deliveryAmount,
    totalReadyToGoAmount,
    totalDiscountAmount,
    promoCodesApplied,
    cartId,
    cartSize,
  })

  instrument(name, data)
}

export const instrumentReferralPageViewed = () => {
  const { name } = analyticsEvents.referralPageViewed()
  instrument(name)
}

export const instrumentReferralModalViewed = (pageSource: PageSource) => {
  const { name, data } = analyticsEvents.referralModalViewed(pageSource)
  instrument(name, data)
}
export const instrumentReferralChannelDismissed = (pageSource: PageSource) => {
  const { name, data } = analyticsEvents.referralChannelDismissed(pageSource)
  instrument(name, data)
}
