import { analyticsEvents, PageSource } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentSubscriptionReactivationNotificationViewed = ({
  pageSource,
}: {
  pageSource: PageSource
}) => {
  const { name, data } =
    analyticsEvents.subscriptionReactivatonNotificationViewed({ pageSource })

  instrument(name, { ...data })
}

export const instrumentSubscriptionReactivationModalViewed = () => {
  const { name, data } = analyticsEvents.subscriptionReactivatonModalViewed()

  instrument(name, { ...data })
}

export const instrumentSubscriptionReactivateConfirmed = ({
  pageSource,
}: {
  pageSource: PageSource
}) => {
  const { name, data } =
    analyticsEvents.subscriptionReactivateConfirmed(pageSource)

  instrument(name, { ...data })
}

export const instrumentEditPreferencesClicked = () => {
  const { name, data } = analyticsEvents.editPreferencesClicked()

  instrument(name, { ...data })
}

export const instrumentSubscriptionReactivateCancelled = () => {
  const { name, data } = analyticsEvents.subscriptionReactivateCancelled()

  instrument(name, { ...data })
}
