import {
  analyticsEvents,
  PageSource,
  ProductType,
} from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

type InstrumentProductViewEvent = {
  productCategory: ProductType
  pageSource?: PageSource
  totalNumberofProductsInPreferences: number
  experimentGroup?: string
}

export const instrumentProductViewEvent = ({
  productCategory,
  pageSource = 'other',
  totalNumberofProductsInPreferences,
  experimentGroup,
}: InstrumentProductViewEvent) => {
  const rawPageSource = sessionStorage.getItem('referrerPath')
  const { name, data } = analyticsEvents.productsViewed({
    productCategory,
    pageSource,
    totalNumberofProductsInPreferences,
    experimentGroup,
  })
  instrument(name, { rawPageSource, ...data })
}
