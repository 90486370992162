import React, { VoidFunctionComponent } from 'react'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { ButtonProps } from '@mindfulchefuk/design-system/Button'
import { NavCTA } from './NavCTA'

interface Props {
  size?: ButtonProps['size']
  fullWidth?: boolean
  onClick?: () => void
  variant?: 'secondary-white' | 'secondary'
}

export const NavSignInCTA: VoidFunctionComponent<Props> = ({
  size,
  fullWidth = false,
  onClick,
  variant = 'secondary',
}) => (
  <NavCTA
    variant={variant}
    testId="nav-cta-sign-in"
    size={size}
    fullWidth={fullWidth}
    href={routesConfig.signIn.pathname}
    onClick={onClick}
  >
    Sign in
  </NavCTA>
)
