import { analyticsEvents, PageSource } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentReportIssueClicked = (params: {
  pageSource: PageSource
  orderType: 'recipe' | 'ready-to-go'
}) => {
  const { name, data } = analyticsEvents.reportAnIssueClicked(params)

  instrument(name, { ...data })
}

export const instrumentReportIssueSkipped = () => {
  const { name } = analyticsEvents.reportAnIssueSkipped()

  instrument(name)
}
