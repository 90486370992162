import { PageSource } from '@mindfulchefuk/analytics'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const usePageSource = (url?: string): PageSource => {
  const { pathname } = useRouter()

  const path = url || pathname

  return useMemo(() => {
    switch (true) {
      case path.includes('sign-in'):
        return 'login page'
      case path.includes('/checkout/success'):
        return 'recipe onboarding success'
      case path.includes('/success'):
        return 'confirmation'
      case path.includes('/my-account/recipes'):
        return 'your recipes'
      case path.includes('/my-account/orders/calendar'):
        return 'calendar'
      case path.includes('/my-account/orders/upcoming'):
        return 'upcoming orders'
      case path.includes('/my-account/orders/past'):
        return 'past orders'
      case path.includes('/my-account/delivery/[deliveryDate]/details'):
        return 'order details page'
      case path.includes('/my-account/delivery'):
        return 'choose recipes page'
      case path.includes('/my-account/details'):
        return 'your details page'
      case path.includes('/my-account/referral'):
        return 'referral page'
      case path.includes('/my-account/contact'):
        return 'contact page'
      case path.includes('/gift-cards'):
        return 'gift card page'
      case path.includes('/healthy-recipes'):
        return 'healthy recipes library page'
      case path.includes('/my-account/subscription'):
        return 'preference page'
      case path.includes('/easy-'):
      case path.includes('/healthy-'):
      case path.includes('/plant-based-'):
      case path.includes('/vegan-recipes'):
        return 'homepage'
      default:
        return 'homepage'
    }
  }, [path])
}
