import React, { useEffect, useState, VoidFunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { Box } from '@mindfulchefuk/design-system/layout/Box'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { ResetButton } from '@mindfulchefuk/design-system/Button'
import { Flex } from '@mindfulchefuk/design-system'
import { instrumentRecipeCTAClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument'
import {
  NavSignInCTA,
  NavSignUpCTA,
} from '@mindfulchefuk/features/Navigation/components/Header/shared'
import { unauthenticatedNav } from '@mindfulchefuk/features/Navigation/data'
import { CTALocation } from '@mindfulchefuk/analytics'
import { isBBCGoodFoodSession } from '@mindfulchefuk/features/Homepage/utils/isBBCGoodFoodSession'
import { SignedOutNavMobile } from './SignedOutNavMobile'
import { SignedOutNavDesktop } from './SignedOutNavDesktop'

export const SignedOutNav: VoidFunctionComponent<{
  isOnboarding?: boolean
  variant: 'light' | 'dark'
  ctaLocation?: CTALocation
}> = ({ isOnboarding, variant, ctaLocation }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const { asPath } = useRouter()

  const buttonVariant = variant === 'light' ? 'secondary' : 'secondary-white'
  const homepageVariant = isBBCGoodFoodSession() ? 'BBCGF ' : null

  useEffect(() => {
    setActiveItem(null)
    setHamburgerOpen(false)
  }, [asPath])

  useEffect(() => {
    function closeSubNav() {
      setActiveItem(null)
    }

    window.addEventListener('scroll', closeSubNav)
    return () => {
      window.removeEventListener('scroll', closeSubNav)
    }
  }, [])

  return (
    <>
      <Box order={-1} display={{ lg: 'none' }} pr={16}>
        <ResetButton
          onClick={() => setHamburgerOpen(true)}
          data-testid="hamburger-button"
        >
          <Icon type="menu" size={32} />
        </ResetButton>
      </Box>
      <SignedOutNavMobile
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={setHamburgerOpen}
        links={unauthenticatedNav}
      />
      <SignedOutNavDesktop
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        links={unauthenticatedNav}
      />
      <Flex align="center" ml="auto">
        <Box display={{ base: 'none', sm: 'block' }} mr={{ base: 12, lg: 16 }}>
          <NavSignInCTA variant={buttonVariant} />
        </Box>
        {!isOnboarding && (
          <NavSignUpCTA
            onClick={() =>
              instrumentRecipeCTAClicked(ctaLocation, homepageVariant)
            }
          />
        )}
      </Flex>
    </>
  )
}
