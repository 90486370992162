import {
  analyticsEvents,
  DeliveryStatus,
  OrderTypes,
  PageSource,
} from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentDeliveryPageViewed = ({
  productsInBasket,
  pageSource,
  deliveryStatus,
}: {
  productsInBasket: OrderTypes
  pageSource: PageSource
  deliveryStatus: DeliveryStatus
}) => {
  const { name, data } = analyticsEvents.deliveryPageViewed({
    productsInBasket,
    pageSource,
    deliveryStatus,
  })

  instrument(name, { ...data })
}

export const instrumentSkipDelivery = ({
  pageSource,
  productTypesInBasket,
}: {
  pageSource: PageSource
  productTypesInBasket: OrderTypes
}) => {
  const { name, data } = analyticsEvents.skipDelivery({
    pageSource,
    productsInBasket: productTypesInBasket,
  })

  instrument(name, { ...data })
}

export const instrumentSkipDeliveryCancelled = () => {
  const { name, data } = analyticsEvents.skipDeliveryCancelled()

  instrument(name, { ...data })
}

export const instrumentChangeDeliveryDateCTAClicked = (
  pageSource: PageSource,
  upcomingDeliveryWeek: number,
  orderType: OrderTypes
) => {
  const { name, data } = analyticsEvents.changeDeliveryDateCTAClicked(
    pageSource,
    upcomingDeliveryWeek,
    orderType
  )

  instrument(name, { ...data })
}

export const instrumentChangeDeliveryModalViewed = (pageSource: PageSource) => {
  const { name, data } =
    analyticsEvents.changeDeliveryDayModalViewed(pageSource)

  instrument(name, { ...data })
}

export const instrumentChangeDeliveryDateSelected = (
  upcomingDeliveryWeek: number,
  pageSource?: PageSource
) => {
  const { name, data } = analyticsEvents.newDeliveryDateSelected(
    upcomingDeliveryWeek,
    pageSource
  )

  instrument(name, { ...data })
}

export const instrumentChangeDeliveryDateConfirmed = (
  upcomingDeliveryWeek: number,
  pageSource?: PageSource
) => {
  const { name, data } = analyticsEvents.newDeliveryDateConfirmed(
    upcomingDeliveryWeek,
    pageSource
  )

  instrument(name, { ...data })
}

export const instrumentShowMoreDatesClicked = (pageSource: PageSource) => {
  const { name, data } = analyticsEvents.showMoreDatesClicked(pageSource)

  instrument(name, { ...data })
}
