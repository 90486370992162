import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { useUpdateSubscription } from '@mindfulchefuk/features/Subscription/hooks/useUpdateSubscription'
import React from 'react'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useRouter } from 'next/router'
import { triggerHotJarEvent } from '@mindfulchefuk/utils/analytics/hotJar/triggerHotJarEvent'
import { instrumentSubscriptionReactivateConfirmed } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentRestartSubscriptionEvents'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'

export const NotificationWarningAccountIsDeactivated = () => {
  const router = useRouter()
  const pageSource = usePageSource()

  const { mutate: updateSubscription } = useUpdateSubscription({
    onSuccess: async () => {
      triggerHotJarEvent('restart subscription confirmed')
      instrumentSubscriptionReactivateConfirmed({
        pageSource,
      })
      router.push(
        {
          pathname: routesConfig.upcoming.pathname,
          query: { subscriptionRestarted: true },
        },
        routesConfig.upcoming.as
      )
    },
  })

  const handleRestartSubscription = () => {
    logEvent({
      category: ANALYTICS_CATEGORIES.myAccount,
      action: 'Restart Subscription Button',
      label: 'Click',
    })

    updateSubscription({ cancelledAt: null })
  }

  return (
    <BannerNotification
      variant="warning"
      title="Subscription deactivated"
      id="warning-account-is-deactivated"
      cta={{
        label: 'Restart subscription',
        onClick: handleRestartSubscription,
      }}
    >
      You do not have an active subscription, but you can buy Ready to Go or get
      a one-off box anytime!
    </BannerNotification>
  )
}
