import React, { FunctionComponent } from 'react'

import { Box, ColorToken, Container, Stack } from '@mindfulchefuk/design-system'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'
import { ProductType } from '@mindfulchefuk/analytics'
import { instrumentNavClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentNavigation'
import { instrumentPlaceAnOrderClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentDashboard'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'
import { useCMSGlobalContent } from '@mindfulchefuk/features/CMS/hooks/useCMSGlobalContent'

import { MCDetails } from '@mindfulchefuk/features/Navigation/components/Footer/MCDetails'
import { Copyright } from '@mindfulchefuk/features/Navigation/components/Footer/Copyright'
import { BCorpLogo } from '@mindfulchefuk/features/Navigation/components/Footer/BCorpLogo'
import { ListItem } from '@mindfulchefuk/features/Navigation/components/Footer/ListItem'
import { FooterBlock } from '@mindfulchefuk/features/Navigation/components/Footer/FooterBlock'
import globalContentFallback from '@mindfulchefuk/features/CMS/data/globalContentFallback.json'
import { useIsBBCGoodFood } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'

export type FooterProps = { variant?: 'dark' | 'light' | 'bbcGoodFood' }

export const Footer: FunctionComponent<FooterProps> = ({
  variant = 'dark',
}) => {
  const { data } = useCMSGlobalContent()
  const pageSource = usePageSource()
  const isLoggedIn = isAuthenticated()
  const isBBCGoodFood = useIsBBCGoodFood()

  const selectedVariant = isBBCGoodFood
    ? footerVariants.bbcGoodFood
    : footerVariants[variant]

  const navigation = data?.navigation || globalContentFallback.navigation
  const socialLinks = data?.socialLinks || globalContentFallback.socialLinks

  const footerBlocks = isLoggedIn
    ? navigation.footer_signed_in
    : navigation.footer

  const trackLinkClick = (linkId: string) => {
    instrumentNavClicked({
      link: linkId,
      section: 'footer',
      pageSource,
    })

    if (shopLinks.includes(linkId)) {
      instrumentPlaceAnOrderClicked({
        isUpsell: 'false',
        pageSource,
        productCategory: linkId as ProductType,
        productType: 'ready to go',
        section: 'top nav',
      })
    }
  }

  return (
    <Box
      as="footer"
      bg={selectedVariant.bg}
      py={[32, 48, 48, 80]}
      color={selectedVariant.color}
    >
      <Container as="nav">
        <Stack
          as="ul"
          p={0}
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 36, md: 0 }}
          justify={{ base: 'start', md: 'space-between' }}
        >
          <ListItem>
            <MCDetails
              isBBCGoodFood={isBBCGoodFood}
              socialLinks={[
                { reference: 'facebook', path: socialLinks.facebook },
                { reference: 'instagram', path: socialLinks.instagram },
                { reference: 'twitter', path: socialLinks.twitter },
              ]}
              onSocialClick={trackLinkClick}
            />
          </ListItem>

          {footerBlocks.map((block) => {
            return (
              <FooterBlock
                key={block.order}
                block={block}
                onClick={trackLinkClick}
              />
            )
          })}

          <ListItem display={{ base: 'block', md: 'none' }}>
            <BCorpLogo />
          </ListItem>

          <ListItem display={{ base: 'block', md: 'none' }} mt={80}>
            <Copyright />
          </ListItem>
        </Stack>
      </Container>
    </Box>
  )
}

const shopLinks = [
  'recipes',
  'meal',
  'smoothie',
  'frozen_desserts',
  'breakfasts',
  'snacks',
  'bundled_products',
]

const footerVariants = {
  dark: {
    bg: 'aubergine',
    color: 'white',
  },
  light: {
    bg: 'salt',
    color: 'aubergine',
  },
  bbcGoodFood: {
    bg: 'bbc-good-food-gray',
    color: 'bbc-good-food-black',
  },
} as {
  [key: string]: {
    bg: ColorToken
    color: ColorToken
  }
}
