import { analyticsEvents, PageSource } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentRecipeRatingsClicked = (params: {
  pageSource: PageSource
  rating: number
  productId: string
  pastDeliveryWeek: number
  productName: string
  isEdit: boolean
  isFromEmail?: boolean
}) => {
  const { name, data } = analyticsEvents.recipeRatingsClicked(
    params as Parameters<typeof analyticsEvents.recipeRatingsClicked>[0]
  )

  instrument(name, { ...data })
}

export const instrumentRecipeRatingChanged = (chosenRating: number) => {
  const { name, data } = analyticsEvents.recipeRatingChanged({ chosenRating })

  instrument(name, { ...data })
}

export const instrumentRatingSuccessPageModalSkipped = () => {
  const { name } = analyticsEvents.ratingSuccessPageModalSkipped()

  instrument(name)
}

export const instrumentRatingSuccessPageModalViewed = (
  ratedViaModal: number
) => {
  const { name, data } = analyticsEvents.ratingSuccessPageModalViewed({
    ratedViaModal,
  })

  instrument(name, { ...data })
}

export const instrumentRatingSuccessPageModalClosed = () => {
  const { name } = analyticsEvents.ratingSuccessPageModalClosed()

  instrument(name)
}

export const instrumentRecipeFeedbackClicked = (feedbackName: string) => {
  const { name, data } = analyticsEvents.recipeFeedbackClicked({ feedbackName })

  instrument(name, { ...data })
}

export const instrumentRecipeFeedbackSubmitted = (
  operation: 'create' | 'update'
) => {
  const { name, data } = analyticsEvents.recipeFeedbackSubmitted({ operation })

  instrument(name, { ...data })
}

export const instrumentRecipeFeedbackSkipped = () => {
  const { name } = analyticsEvents.recipeFeedbackSkipped()

  instrument(name)
}

export const instrumentRecipeRatingEditClicked = () => {
  const { name } = analyticsEvents.recipeRatingEditClicked()

  instrument(name)
}

export const instrumentRecipeRatingEditSkipped = () => {
  const { name } = analyticsEvents.recipeRatingEditSkipped()

  instrument(name)
}
